import React from "react";
import ImageWithFallback from "@/components/shared/ImageWithFallback";
import TextLink from "@/components/shared/TextLink";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { Path } from "@/utilities/types";
import { FaqBlockProps } from "../FaqMiniBlock";

type ButtonTarget = Path<FaqBlockProps, ["contact_button_target", number]>;

interface EmployeeProps {
  title?: string | null;
  body?: string | null;
  buttonText?: string | null;
  buttonTarget?: ButtonTarget;
  image?: {
    default?: string | null;
    description?: string | null;
  } | null;
  className?: string;
}

const Employee: React.FC<EmployeeProps> = ({
  title,
  body,
  buttonText,
  buttonTarget,
  image,
  className,
}) => {
  const button = useButtonTarget(buttonTarget);

  return (
    <div
      className={`tablet:col-span-5 tablet:px-11 tablet:pt-[7.5rem] tablet:pb-56 relative col-span-full pb-10 pt-8 ${
        className ?? ""
      }`}
    >
      <div className="relative z-10">
        {title && (
          <h3 className="text-h4-sm tablet:w-3/4 tablet:text-h3-sm w-1/2 font-bold tracking-wide text-white">
            {title}
          </h3>
        )}
        <p className="text-p-sm tablet:mt-6 tablet:text-p mt-4 w-2/3 font-serif tracking-wide text-white">
          {body}
        </p>
        {buttonText && button.isValid && (
          <TextLink
            href={button.href}
            onClick={button.onClick}
            type={button.type}
            className="tablet:mt-10 mt-6 inline-flex"
            variant="dark"
          >
            {buttonText}
          </TextLink>
        )}
      </div>

      {image?.default && (
        <div className="tablet:hidden tablet:h-[380px] tablet:w-[285px] desktop-s:block absolute bottom-0 right-0 block aspect-[3/4] h-[171px] w-[128px]">
          <ImageWithFallback
            src={image.default}
            alt={image.description ?? ""}
            layout="fill"
            objectPosition="bottom"
            objectFit="contain"
          />
        </div>
      )}
    </div>
  );
};

export default Employee;
